import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

import "../style/blog-singlepage.less";
import SectionTitle from "../components/sectiontitle";

export default function BookShow({ data }) {
  // Cloned with books-grid.js
  const bookRankingStyle = {
    5: "<strong><u>Excellent!</u></strong>",
    4: "Great!",
    3: "Decent",
    2: "Bad.",
    1: "Terrible."
  }

  return (
    <Layout>
      <Seo
        lang="en"
        title={`${data.markdownRemark.frontmatter.title} by ${data.markdownRemark.frontmatter.author}`}
        description={"Jon's book thoughts"}
      />
      <div className="container">
        <article className="blog-post">
          <div className="section-title">
            <SectionTitle title={data.markdownRemark.frontmatter.title} />
          </div>
          <div className="head text-primary">
            <div>
              <a href={data.markdownRemark.frontmatter.link} title={data.markdownRemark.frontmatter.title} target="_blank" rel="noopener noreferrer">
                <GatsbyImage image={data.markdownRemark.frontmatter.cover.childImageSharp.gatsbyImageData} imgStyle={{ borderRadius: "10px", border: "solid #F55555" }} />
              </a>
            </div>
            <p>{data.markdownRemark.frontmatter.author}</p>
            <i dangerouslySetInnerHTML={{ __html: bookRankingStyle[parseInt(data.markdownRemark.frontmatter.stars)] }} ></i>
          </div>
          <div className="content row flex">
            <div
              className="col s12 m11 l10"
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.html
              }}
            ></div>
          </div>
        </article>
      </div>
    </Layout>
  );
}

export const query = graphql`
query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    id
    frontmatter {
      title
      stars
      link
      author
      cover {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 200)
        }
      }
    }
  }
}
`;
